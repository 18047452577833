<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div style="">

        <v-row>
            <v-col>
                <v-container class="my-5">
                    <v-row class="px-3">
                        <v-col cols="0" md="2">
                            <admin-main-menu></admin-main-menu>
                        </v-col>
                        <v-col cols="12" md="9">
                            <v-row>
                                <v-col cols="12" class="sub-title-3">
                                    기본 정보
                                </v-col>
                                <table class="table-left-s">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        <col style="width:75%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">펜션명</th>
                                        <td class="td">
                                            <input id="homeNm" v-model="homeInfo.homeNm">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">홈페이지 타이틀</th>
                                        <td class="td">
                                            <input id="homeTitle" v-model="homeInfo.homeTitle">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">홈페이지주소</th>
                                        <td class="td">
                                            <input id="homeAddr" v-model="homeInfo.homeAddr">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">관리자ID</th>
                                        <td class="td">
                                            <input id="usrId" v-model="homeInfo.usrId" readonly>

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">비밀번호</th>
                                        <td class="td">
                                            <input type="password" id="usrPw" v-model="homeInfo.usrPw"
                                                   style="">*변경시에만 입력하세요.

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">비밀번호확인</th>
                                        <td class="td">
                                            <input type="password" id="usrPwConfirm"
                                                   v-model="homeInfo.usrPwConfirm" @change="isSame"
                                                   style="">
                                            &nbsp;&nbsp;<span id="same"></span>
                                        </td>
                                    </tr>
                                </table>
                            </v-row>

                            <v-row>
                                <v-col cols="12" class="sub-title-3">
                                    연락처 정보
                                </v-col>
                                <table class="table-left-s">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        <col style="width:75%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">전화번호</th>
                                        <td class="td">
                                            <input id="homeTel" v-model="homeInfo.homeTel">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">팩스</th>
                                        <td class="td">
                                            <input id="homeFax" v-model="homeInfo.homeFax">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">담당자명</th>
                                        <td class="td">
                                            <input id="mstrNm" v-model="homeInfo.mstrNm">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">연락처</th>
                                        <td class="td">
                                            <input id="mstrTel" v-model="homeInfo.mstrTel">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">이메일</th>
                                        <td class="td">
                                            <input id="homeMail" v-model="homeInfo.homeMail">
                                        </td>
                                    </tr>
                                </table>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="sub-title-3">
                                    사업자 정보
                                </v-col>
                                <table class="table-left-s">
                                    <colgroup>
                                        <col style="width:25%"/>
                                        <col style="width:75%"/>
                                    </colgroup>
                                    <tr>
                                        <th class="th">사업자번호</th>
                                        <td class="td">
                                            <input id="saupNo" v-model="homeInfo.saupNo">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">상호명</th>
                                        <td class="td">
                                            <input id="saupNm" v-model="homeInfo.saupNm">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">대표자명</th>
                                        <td class="td">
                                            <input id="saupMstrNm" v-model="homeInfo.saupMstrNm">

                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">사업장주소</th>
                                        <td class="td">
                                            <input id="saupAddr" v-model="homeInfo.saupAddr">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">업태</th>
                                        <td class="td">
                                            <input id="saupUptai" v-model="homeInfo.saupUptai">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">종목</th>
                                        <td class="td">
                                            <input id="saupType" v-model="homeInfo.saupType">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="th">통신판매신고번호</th>
                                        <td class="td">
                                            <input id="saupMobr" v-model="homeInfo.saupMobr">
                                        </td>
                                    </tr>
                                </table>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-btn
                                            color="primary"
                                            class="mx-4 white--text center-block"
                                            small
                                            @click="btnClick"
                                    >
                                        저장
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import AdminMainMenu from '../../components/AdminMainMenu'
    export default {
        name: 'AdminMain',
        components: {
            AdminMainMenu
        },
        data: () => ({
            usrInfo: {
                usrId: "",
                usrPwConfirm: "",
                usrTel: "",
                usrMail: "",
                usrAddr: "",
            },
            homeInfo: {

            },
            isPwConfirm: false,



        }),
        created: function () {
            // 맨위로
            window.scrollTo(0, 0);
            //this.$isAdminCheck(this.$store.state.admin.isLogin);
            //this.$isAuthCheck(this.$getCookie("AUTH"));
            this.getData();


        },
        methods: {
            getData() {
                return this.$store.dispatch("admin/getHomeInfo", {})
                    .then((resp) => {
                        setTimeout(() => {

                            resp.message.usrPw = "";
                            this.homeInfo = resp.message;
                        }, 300)
                    })
                    .catch((err) => {
                    })

            },
            // 비밀번호 동일문자 확인
            isSame() {

                var pw = this.homeInfo.usrPw;
                var confirmPW = this.homeInfo.usrPwConfirm;
                if (pw.length < 3 || pw.length > 16) {
                    window.alert('비밀번호는 3글자 이상, 16글자 이하만 이용 가능합니다.');
                    document.getElementById('usrPw').value = document.getElementById('usrPwConfirm').value = '';
                    document.getElementById('same').innerHTML = '';
                }
                if (document.getElementById('usrPw').value != '' && document.getElementById('usrPwConfirm').value != '') {
                    if (document.getElementById('usrPw').value == document.getElementById('usrPwConfirm').value) {
                        document.getElementById('same').innerHTML = '비밀번호가 일치합니다.';
                        document.getElementById('same').style.color = 'blue';
                        this.isPwConfirm = true;
                    } else {
                        document.getElementById('same').innerHTML = '비밀번호가 일치하지 않습니다.';
                        document.getElementById('same').style.color = 'red';
                        this.isPwConfirm = false;
                    }
                }
            },
            btnClick() {

                if(this.homeInfo.usrPw.length > 0){
                    if(!this.isPwConfirm){
                        alert("비밀번호를 확인해 주세요.");
                        return;
                    }
                }else {

                    this.homeInfo.usrPw = "";

                }

                return this.$store.dispatch("admin/updateHomeInfo", this.homeInfo)
                    .then((resp) => {
                        alert("저장완료");
                        setTimeout(() => {
                            location.href = "/admin/main"
                        }, 300)
                    })
                    .catch((err) => {
                    })



            },
            menuClick(route) {
                location.href = route;
                //this.$router.push({path: route});
            }

        },
        computed: {
            //usrInfo() { return this.$store.state.reservation.usrInfo }
        },
    }
</script>

<style>

</style>
